import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { SignatureDialogComponent } from './signature-dialog/signature-dialog.component';
import { OrderService } from 'src/app/shared/services/order.service';
import { first } from 'rxjs/operators';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-signature-page',
  templateUrl: './signature-page.component.html',
  styleUrls: ['./signature-page.component.scss']
})
export class SignaturePageComponent implements OnInit {
  routeId: any;
  url: any;
  routeLink1: any;
  page: any;
  isLoading: boolean = false;
  serviceValue: any;
  tableResponse: any;
  routeLink2: any;
  routeLink3: any;
  module: any;
  id: any;
  payId: any;
  response: any;
  displayedColumns: string[] = ['payment_status', 'credit', 'due', 'cost'];
  dataSource!: MatTableDataSource<any>;
  name: any;
  signature: any;
  config: any = {
    width: 500,
    height: 150,
    paddingX: 50,
    paddingY: 80,
    font: ["50px", "'Homemade Apple'"],
    color: "black",
    customFont: {
      name: "'Homemade Apple'",
      url: "https://fonts.googleapis.com/css?family=Homemade+Apple"
    }
  };
  config1: any = {
    width: 500,
    height: 150,
    paddingX: 50,
    paddingY: 80,
    font: ["50px", "'Homemade Apple'"],
    color: "black",
    customFont: {
      name: "'Homemade Apple'",
      url: "https://fonts.googleapis.com/css?family=Homemade+Apple"
    }
  };
  firstLastName: any;
  img1: any;
  imageSign: any;
  nameChange: any;
  nameChange1: any;
  imageSign1: any;
  button : any;
  SignatureValue: any;
  username: any;
  img: any;
  imgLoading: boolean = false;
  loading: boolean = false;
  pay_Id: any;
  orderData: any;
  signatureImage: any;
  getSigImage: any;
  lat: any;
  long: any;
  
  constructor(
    private route: ActivatedRoute,
    private agencyService: AgencyService, public dialogService: MatDialog,
    private orderService: OrderService,
    private _snackbar: SnackbarService,
    public dialog: MatDialog,) { 
    // this.route.paramMap.subscribe((param: any) => {
    //   this.routeId = param.params.id;
    // });
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        resp => {
          resolve({
            lng: resp.coords.longitude.toFixed(7),
            lat: resp.coords.latitude.toFixed(7)
          });
        },
        err => {
          reject(err);
        }
      );
    });
  }
  ngOnInit(): void {
    // console.log(this.routeId)

    this.url = window.location.href;
    // this.url = 'https://vivahevent.novuscode.com/confirmation?module=agency&id=2&payId=1'
    this.routeLink1 = this.url.split('/');
    console.log(this.routeLink1)
    this.routeLink2 = this.routeLink1[3].split('?')
    this.routeLink3 = this.routeLink2[1].split('&')
    this.module = this.routeLink3[0].split('module=')
    this.id = this.routeLink3[1].split('id=')
    this.pay_Id = this.routeLink3[2].split('payId=')
    this.routeId = atob(decodeURIComponent(decodeURIComponent(this.id[1])))
    this.payId = atob(decodeURIComponent(decodeURIComponent(this.pay_Id[1])))
    
    if(this.module[1] == "agency"){
      this.loadVendorById()
    }else if(this.module[1] == "order"){
      this.orderById()
    }
  }

  loadVendorById() {
    this.isLoading = true
    var formData = new FormData();
    formData.append("id", this.routeId);

    this.agencyService.getVendorById(formData).subscribe(
      (resp) => {
        console.log('user', resp.data[0].id);
        const payload = {
          ...resp.data[0],
          name: resp.data[0].name,
          mobile: resp.data[0].mobile,
          email: resp.data[0].email,
          cost: resp.data[0].cost,
          // note: resp.data[0].note,
          // ...resp.data,
          category: resp.data[0].category,
          service_id: resp.data[0].category_id,
          credit: resp.data[0].credit,
          due: resp.data[0].due
        };
        this.name = payload.name
        this.response = payload
        this.vendorPayById()
    })
  }

  vendorPayById(){
    var formData = new FormData();
    formData.append("vendor_id", this.routeId);
    formData.append("pay_id", this.payId);
    this.agencyService.vendorPaymentHistory(formData).subscribe(
      (data:any) => {
        console.log(data)
        this.tableResponse = data.data
        this.getSigImage = data.data.signature
      console.log(this.tableResponse)
      var table = []
      table.push(this.tableResponse)
      this.dataSource = new MatTableDataSource(table || []);
      this.isLoading = false
    },
    (error:any) => {
      console.log(error);
      this.isLoading = false
    }
    )
  }

  orderById() {
    this.isLoading = true;
    var formData = new FormData();
    formData.append("order_id", this.routeId);

    this.orderService.orderInfo(formData).subscribe(
      (resp:any) => {
        console.log('user', resp);
        const payload = {
          ...resp.data,
          name: resp.data.name,
        };
        this.name = payload.name
        this.response = payload;
        this.orderPaymentId()

      },
      (error) => {
        console.log(error);
      }
    );
  }

  orderPaymentId(){
    this.isLoading = true;
    var formData = new FormData();
    formData.append("order_id", this.routeId);
    formData.append("pay_id", this.payId);

    this.orderService.orderPayInfo(formData).subscribe(
      (resp:any) => {
        console.log(resp)
        this.tableResponse = resp.data
        this.getSigImage = resp.data.signature
        console.log(this.tableResponse)
        var table = []
        table.push(this.tableResponse)
        this.dataSource = new MatTableDataSource(table || []);
        this.isLoading = false
      },
      (error) => {
        console.log(error);
      }
    );
  }

  openSignDialog(){
    var dataArray = {
      username: this.name,
    }
    console.log(dataArray)
    this.imgLoading = true
    this.username = this.name
    console.log('this.username',this.username)
    this.nameChange = setInterval(() => {
      console.log('start')
      this.username = this.username + ' '
      }, 100);
  }
  getImage(data: any) {
    this.img = data;
    console.log('this.img', this.img)
      
    setTimeout(() => {
      this.imageSign = data
    }, 2000);
    if(this.img == this.imageSign){
      // console.log('this.imageSign', this.imageSign)
      // console.log('stop')
      this.imgLoading = false
      clearInterval(this.nameChange)
    }
  }

  getImage1(data: any) {
    // console.log(data)
    this.img1 = data
    console.log('this.img1', this.img1)
    setTimeout(() => {
      this.imageSign1 = data
    }, 2000);
    if(this.img1 == this.imageSign1){
      console.log('this.imageSign1', this.imageSign1)
      console.log('stop1')
      this.isLoading = false;
      clearInterval(this.nameChange1)
    }
  }
  onFinish(value:any){
    // console.log(value)
    
    const dialogRef = this.dialog.open(SignatureDialogComponent, {
      height: 'auto',
      width: '350px',
      // data: item,
      panelClass: ["dialog-design"],
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if(result == "okay"){
        this.loading = true
        this.SignatureValue = value
        if(this.module[1] == 'agency'){
          this.updateVendor()
        }else if(this.module[1] == 'order'){
          this.updateOrder()
        }
      }
    })
    
  }
  selectedimage(value:any, number:any){
    this.SignatureValue = value
  }

  updateVendor(){
    // console.log(this.SignatureValue)
    this.getPosition().then(pos => {
      console.log('pos', pos);
      this.lat = pos.lat
      this.long = pos.long
    });
    this.loading = true;
    var formData = new FormData();
    formData.append("vendor_id", this.routeId);
    formData.append("payment_id", this.payId);
    formData.append("payment_confirmation_status", '1');
    formData.append("signature", this.SignatureValue);
    formData.append("lat", this.lat);
    formData.append("lon", this.long);
    this.agencyService
      .updateVendorPayment(formData)
      .pipe(first())
      .subscribe(
        (resp: any) => {
          // console.log('resp=>', resp);
          this._snackbar.openSnackBar('Vendor Updated successfully.', '');
          this.loadVendorById()
          this.loading = false
        },
        (error: any) => {
          this.loading = false;
          this._snackbar.openSnackBarError(error.message, '');
        }
      );
  }

  updateOrder(){
    // console.log(this.SignatureValue)
    this.getPosition().then(pos => {
      console.log('pos', pos);
      this.lat = pos.lat
      this.long = pos.long
    });
    this.loading = true;
    var formData = new FormData();
    formData.append("order_id", this.routeId);
    formData.append("payment_id", this.payId);
    formData.append("payment_confirmation_status", '1');
    formData.append("signature", this.SignatureValue);
    formData.append("lat", this.lat);
    formData.append("lon", this.long);
    this.orderService.updateOrderPay(formData)
      .pipe(first())
      .subscribe(
        (resp: any) => {
          // console.log('resp=>', resp);
          this._snackbar.openSnackBar('Order Updated successfully.', '');
          this.orderById()
          this.loading = false
        },
        (error: any) => {
          this.loading = false;
          this._snackbar.openSnackBarError(error.message, '');
        }
      );
  }
}
