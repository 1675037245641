<mat-sidenav-container>


  <mat-sidenav #sidenav="matSidenav" opened="true" class="mat-elevation-z8 card-background-color"
    ngClass.lt-md="card-background-color-lt-md">
    <div fxaLayout="row" fxLayoutAlign="start" class="title-text-margin">
      <h1 class="mat-h1 font-color-primary2">Choice Snack Bar</h1>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <!-- <div style="height: calc(70vh - 10px);"> -->

        <mat-nav-list>
          <span *ngFor="let item of menuitem">
            <span *ngIf="item.children && item.children.length > 0">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        <button mat-button class="menu-button" (click)="getReminder()" routerLinkActive="active-list-item">
                          <mat-icon>{{item.iconName}}</mat-icon>
                          {{item.displayName}}
                        </button>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <span *ngFor="let child of item.children">
                    <mat-list-item>
                        <button mat-button class="menu-button" (click)="getReminder()" routerLink="{{ child.route }}" routerLinkActive="active-list-item" [routerLinkActiveOptions]="{exact: true}">
                          <mat-icon>{{child.iconName}}</mat-icon>
                          {{child.displayName}}
                        </button>
                    </mat-list-item>
                  </span>
                </mat-expansion-panel>
              </mat-accordion>
            </span>
            <span *ngIf="!item.children || item.children.length === 0">
              <mat-list-item>
                  <button mat-button class="menu-button" (click)="getReminder()" routerLink="{{ item.route }}" routerLinkActive="active-list-item">
                    <mat-icon>{{item.iconName}}</mat-icon>
                    {{item.displayName}}
                  </button>
              </mat-list-item>
            </span>
          </span>
        </mat-nav-list>
      <!-- </div> -->
      <mat-divider></mat-divider>
      <div fxLayoutGap="10px">
        <button mat-button class="menu-button" routerLinkActive="active-list-item" routerLink="/admin/profile-setting">
          <mat-icon>account_circle</mat-icon>
          <span>Profile Setting</span>
        </button>
        <button mat-button class="menu-button" (click)="onLoggedout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar ngClass.lt-md="border-bottom-toolbar-display" color="primary">
      <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
        <mat-icon class="color-primary" *ngIf="!sidenav.opened"> menu </mat-icon>
        <mat-icon class="color-primary" *ngIf="sidenav.opened"> close </mat-icon>
      </button>


      <div fxlayout="row" class="full-width border-bottom-toolbar" ngClass.lt-md="border-bottom-toolbar-md"
      fxLayoutAlign="center center">
        <span fxHide.xs class="fileter-width">
          <span class="mat-h1" style="color: #000;">{{viewedPage}}</span>
        </span>


        <div fxLayout="row" fxFlex="" fxLayoutAlign="end center" fxLayoutGap="15px" style="margin-bottom: 5px;">
          <div>
            <button mat-icon-button routerLink="/admin/reminder">
              <mat-icon style="color: #000;" matBadgeColor="warn" [matBadge]="this.todayleadLength" *ngIf="this.todayleadLength > 0">notifications_none</mat-icon>
              <mat-icon style="color: #000;" matBadgeColor="warn" *ngIf="this.todayleadLength == 0">notifications_none</mat-icon>
            </button>
          </div>
          <div fxLayoutAlign="center center" [matMenuTriggerFor]="menu1" class="profile-img" *ngIf="!loading">
              <h2>{{this.firstLetter}}</h2>
          </div>

          <mat-menu #menu1="matMenu" class="profile-menu">
            <div>
              <mat-nav-list>
                <div fxLayout="row" fxLayoutAlign="start start" style="text-align: center;">
                  <span>
                    <a routerLink="/admin/profile-setting">
                    <mat-icon>supervised_user_circle</mat-icon>
                    <p>Profile</p>
                    </a>
                  </span>

                  <span (click)="onLoggedout()">
                    <mat-icon> vpn_key</mat-icon>
                    <p>Logout</p>
                  </span>
                </div>
              </mat-nav-list>
            </div>
          </mat-menu>

          <div class="">
            <mat-menu #menu2="matMenu" class="my-menu notification-height">
              <div class="notification-container" [ngClass.xs]="'notification-containermobile'">
                <mat-nav-list>
                  <div fxLayout="row" fxLayoutAlign="space-between start" class="notification-content-margin">
                    <div fxLayoutAlign="center center">
                      <p class="mat-h3" style="padding: 0px 16px 0px 0px;">Notifications</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <mat-list-item mat-line *ngFor="let msg of this.notificationDetail;  let i = index">
                    <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 5px 0px;">
                      <div>
                        <p style="word-wrap:break-word ; text-align: justify;" style="margin-bottom: 0px;">
                          Event Type: {{msg.event_type}} <span class="badge"></span>
                        </p>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </mat-nav-list>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </mat-toolbar>
    <div class="contentside backgroud-color-white">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- <router-outlet></router-outlet> -->