<mat-card
*ngIf="isLoading"
class="card"
style="display: flex; justify-content: center; align-items: center"
>
<mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
</mat-progress-spinner>
</mat-card>

<div style="background-color: #eaeaea;padding: 20px 0px;" ngStyle.xs="padding: 0px" *ngIf="!isLoading">
    <section class="section" ngClass.xs="section-xs" ngClass.sm="section-sm">
        <div fxLayoutAlign="center center" fxLayout="column" class="position-relative">
            <div style="width: 100%;">
                <img src="../assets/images/home-4-rev-sl-img-1.jpg" height="335" width="100%">
            </div>
            <p class="mat-h1 heading-sig" ngStyle.xs="fontSize:35px;">Choice Snack Bar</p>
        </div>

        <div class="details-part" fxLayout.xs="column" fxLayout.sm="row">
            <div fxFlex="50" fxFlex.xs="100">
                <p><b>Name: </b>{{this.response.name}}</p>
                <p><b>Email Address: </b>{{this.response.email}}</p>
                <p><b>Mobile No.: </b>{{this.response.mobile}}</p>
            </div>

            <div fxFlex="50" fxFlex.xs="100" style="text-align: end;" ngStyle.xs="text-align: start;">
                <span *ngIf="this.module[1] == 'agency'">
                    <!-- <p><b>Category: </b>{{this.response.category}}</p>
                    <p><b>Service: </b>{{this.response.service_name}}</p> -->
                    <p><b>Payment Method: </b>{{this.tableResponse.payment_status}}</p>
                    <p><b>Payment Date: </b>{{this.tableResponse.payment_date}}</p>
                    <p><b>Place: </b>{{this.tableResponse.place}}</p>
                    <p><b>Comment: </b>{{this.tableResponse.note}}</p>
                </span>
                <span *ngIf="this.module[1] == 'order'">
                    <p *ngIf="this.response.event_type != null"><b>Event Type: </b>{{this.response.event_type}}</p>
                    <p><b>Event Date: </b>{{this.response.date | date:'dd/MM/yyyy'}}</p>
                    <p><b>No Of Person: </b>{{this.response.no_of_person }}</p>
                    <p><b>Payment Date: </b>{{this.tableResponse.payment_date}}</p>
                    <p><b>Place: </b>{{this.tableResponse.place}}</p>
                </span>
            </div>
        </div>

        <div class="table-margin">
            <p class="mat-h1" fxLayoutAlign="center center">Payment Details</p>
                <div class="table-design-sig" ngClass.xs="table-design-xs">
                    <table mat-table [dataSource]="dataSource">

                        <ng-container matColumnDef="payment_status">
                            <th mat-header-cell *matHeaderCellDef> 	Payment Method </th>
                            <td mat-cell *matCellDef="let element"> {{element.payment_status}} </td>
                        </ng-container>

                        <ng-container matColumnDef="credit">
                            <th mat-header-cell *matHeaderCellDef> Credit</th>
                            <td mat-cell *matCellDef="let element"> {{element.credit}} </td>
                        </ng-container>

                        <ng-container matColumnDef="due">
                            <th mat-header-cell *matHeaderCellDef> Due</th>
                            <td mat-cell *matCellDef="let element"> {{element.due}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cost">
                            <th mat-header-cell *matHeaderCellDef> Cost </th>
                            <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell text-center" colspan="7" style="text-align: center;">
                                No data matching the filter
                            </td>
                        </tr>
                    </table>
                </div>
        </div>

        <div>
            <div class="signature-button" ngClass.xs="signature-button-xs" fxLayoutAlign="center center"> 
                <div
                *ngIf="imgLoading"
                style="display: flex; justify-content: center; align-items: center"
                >
                    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="this.getSigImage == null">
                    <button mat-button (click)="openSignDialog()" *ngIf="!imageSign && !imgLoading">Sign Here</button>
                    <img class="img-fluid bd rounded"  *ngIf="imageSign" 
                    style="height: 5rem; width: 275px;"  [src]="imageSign" (click)="selectedimage(imageSign, 1)">
                    <input type="radio" style="display: none;" [(ngModel)]="username" [signGenSrc]="username" [signGenConfig]="config" (signGenImg)="getImage($event)">
                </div>
                <div *ngIf="this.getSigImage != null">
                    <img [src]="this.getSigImage" alt="Signature Image" width="100%">
                </div>
            </div>
            <p ngStyle.xs="text-align:center"><span class="mat-h2 signature-line" ngClass.xs="signature-line-xs">Signature as an acceptance here</span></p>

            <div class="footer-button" fxLayoutAlign="center center" *ngIf="this.getSigImage == null">
                <div class="text-center">
                <button type="button" mat-raised-button color="primary" class="save-button" [disabled]="!imageSign" (click)="onFinish(imageSign)">
                    <span *ngIf="!loading">Finish</span><span *ngIf="loading" [ngClass]="{ 'spinner' : loading, 'add-button' : loading}"></span>
                </button>
                </div>
            </div>
        </div>
    </section>
</div>