import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { MaterialModule } from './modules/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {ChartModule} from 'angular-highcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpconfigInterceptor } from './shared/_helpers/httpconfig.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogoutDialogComponent } from './layout/sidenav/logout-dialog/logout-dialog.component';
// import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
// import {
//   GoogleLoginProvider,
//   FacebookLoginProvider
// } from '@abacritt/angularx-social-login';
import { AuthConfig, OAuthModule, OAuthService } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  issuer: 'https://accounts.google.com', // Google's OAuth2 endpoint
  redirectUri: 'http://localhost:4200/admin/crm-lead-portal/add',
  clientId: '853048434584-63u6prm7m46mterg6sl174575fvu2e5v.apps.googleusercontent.com',
  scope: 'openid profile email',
};
@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    AppComponent,
    SidenavComponent,
    BlankLayoutComponent,
    CommonLayoutComponent,
    LogoutDialogComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ChartModule,
    // SocialLoginModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://api.example.com'],
        sendAccessToken: true,
      },
      ...authConfig,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpconfigInterceptor, multi: true },
    // {
    //     provide: 'SocialAuthServiceConfig',
    //     useValue: {
    //       autoLogin: false,
    //       providers: [
    //         {
    //           id: GoogleLoginProvider.PROVIDER_ID,
    //           provider: new GoogleLoginProvider(
    //             '853048434584-63u6prm7m46mterg6sl174575fvu2e5v.apps.googleusercontent.com'
    //           )
    //         },
    //       ],
    //       onError: (err) => {
    //         console.error(err);
    //       }
    //     } as SocialAuthServiceConfig,
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(private oauthService: OAuthService) {
  //   this.configureOAuth();
  // }

  // configureOAuth() {
  //   // Configure your OAuth settings here (authConfig)
  //   this.oauthService.configure(authConfig);
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin();
  // }
}
