import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadPortalService {

  constructor(private http: HttpClient) { }
  private handleError(error: any) {
    return throwError(error);
  }
  leadPost(formdata:any) {
    return this.http.post<any>(`${environment.apiUrl}/add/lead`, formdata )
    .pipe(map(resp => {

      // console.log('resp', resp)
    }));
  }

  getLead(payload:any): Observable<any> {
    if(payload == ""){
      return this.http.get<any>(`${environment.apiUrl}/leadhistory`)
      .pipe(catchError(this.handleError));
    }else{
      return this.http.get<any>(`${environment.apiUrl}/leadhistory?start_date=${payload.start_date}&end_date=${payload.end_date}`)
      .pipe(catchError(this.handleError));
    }
  }

  getTodayLead(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/todayFollwUp`)
      .pipe(catchError(this.handleError));
  }

  getReminder(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/reminder`)
      .pipe(catchError(this.handleError));
  }

  getFilterLead(payload:any): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/leadhistory?lead_stage=${payload}`)
      .pipe(catchError(this.handleError));
  }
  
  getLeadFollow(): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/leadfollowuphistory`)
      .pipe(catchError(this.handleError));
  }

  getLeadById(formData: any){
    return this.http.post<any>(`${environment.apiUrl}/show/lead`, formData)
      .pipe(catchError(this.handleError));
  }

  updateLeadById(formData: any){
    return this.http
    .post<any>(`${environment.apiUrl}/update/lead`, formData)
      .pipe(catchError(this.handleError));
  }

  generateOrder(formData: any){
    return this.http
    .post<any>(`${environment.apiUrl}/generateOrder`, formData)
      .pipe(catchError(this.handleError));
  }

  getMonthlyLead(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/monthlyLead`, formData)
      .pipe(catchError(this.handleError));
  }

  deleteLeadById(formData: any){
    return this.http
    .post(`${environment.apiUrl}/delete/lead`, formData)
      .pipe(catchError(this.handleError));
  }

  items(formdata:any) {
    return this.http.post<any>(`${environment.apiUrl}/itemhistory`, formdata )
    .pipe(catchError(this.handleError));
  }

  // // GetSourceList-api
  // getSourceList(): Observable<any>{
  //   return this.http.get<any>(`${environment.apiUrl}/viewSource`)
  //   .pipe(catchError(this.handleError));
  // }
}
